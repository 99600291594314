import { ElementType, PropsWithChildren } from "react";
import styled from "styled-components";

interface TextProps {
  fontStyle?: string;
  fontWeight?: string | number;
  color?: string;
  style?: any;
}

const Inner = styled.div<TextProps>`
  font-weight: ${(p) => p.fontWeight || "400"};
  font-family: "AIAIAI${(p) => p.fontStyle || ""}";
  color: ${(p) => (p.color ? p.color : "inherit")};
`;

export function Text(props: PropsWithChildren<TextProps & { as?: ElementType }>) {
  const { as = "div", fontStyle, fontWeight, color, style, children } = props;
  return (
    <Inner
      as={as}
      fontStyle={fontStyle}
      fontWeight={fontWeight}
      color={color}
      style={style}
    >
      {children}
    </Inner>
  );
}

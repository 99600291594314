import { useEffect, useRef } from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  background-color: ${(p) => p.backgroundColor || "transparent"};
  position: relative;
  background-image: url(${(p) => p.src});
  background-size: cover;
  canvas {
    width: 100%;
  }
`;

const loadImg = (src) =>
  new Promise((resolve) => {
    const img = new Image();
    img.src = src;
    img.onload = () => resolve(img);
  });

function Hero3D(props) {
  const { src, fallbackSrc, backgroundColor } = props;
  // const wrapper = useRef();
  const canvasRef = useRef();
  const size = 1400;
  const totalFrames = 12; // fixed??
  const lastFrame = useRef(-1); // which ine is showed...

  // console.log('SRC', src);

  useEffect(() => {
    // setup canvas?
    if (!canvasRef.current) return;

    const canvas = canvasRef.current;

    function getScrollFrame(elem) {
      let frame = 0;

      const canvasRect = elem.getBoundingClientRect();

      // bottom pos relative to top of page
      const bottomPos = window.scrollY + canvasRect.bottom;
      // console.log(bottomPos);

      // trigger should either be bottom of image (landscape) OR bottom of page (portrait)
      const trigger = Math.max(
        bottomPos - canvasRect.height / 5,
        window.innerHeight + 50
      );

      if (window.scrollY + window.innerHeight > trigger) {
        // the distance of the entire animation shouild equal to a third of the height of the current image
        const animationDistance = canvasRect.height / 5;
        // how many px one frame should be visible
        const frameDistance = animationDistance / totalFrames;
        // hiow many pixels we have currently scrolled under the "trigger"
        const scrollProgress = window.scrollY + window.innerHeight - trigger;

        // the current frame the canvas should display
        const curFrame = Math.floor(scrollProgress / frameDistance);
        // cap curFrame by totalFrames
        frame = Math.min(curFrame, totalFrames - 1);
        // canvasFrame.current = curFrame;
      }
      // console.log(frame);
      return frame;
    }

    // console.log(canvas.current);

    let animFrame;

    async function setupCanvas(canvas) {
      // canvasFrame.current = getScrollFrame();

      // remove compress query params from prismic link
      // console.log(src);
      const srcOrg = src.split("?")[0];
      // console.log(srcOrg);
      const atlas = await loadImg(srcOrg);

      // console.log(atlas.width);
      // console.log(atlas.height);

      const ctx = canvas.getContext("2d");

      canvas.width = size;
      canvas.height = size;

      function renderCanvas() {
        animFrame = window.requestAnimationFrame(renderCanvas);
        // const frame = canvasFrame.current;
        const frame = getScrollFrame(canvas);
        // if (frame === lastFrame.current) return;
        /* prettier-ignore */
        ctx.drawImage(atlas, 0, frame * size, size, size, 0, 0, size, size);
        // lastFrame.current = frame;
      }

      renderCanvas();
    }

    setupCanvas(canvas);

    /*
    function handleScroll(e) {
      canvasFrame.current = getScrollFrame();
    }
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
    */

    return () => {
      if (animFrame) cancelAnimationFrame(animFrame);
    };
  }, [canvasRef.current]);

  return (
    <Wrapper backgroundColor={backgroundColor}>
      <canvas ref={canvasRef} />
    </Wrapper>
  );
}

export default Hero3D;

import Head from "next/head";
import Block, { BlockContent, TopSpacerBlock } from "~/components/Block";
import BreadCrumbs from "~/components/BreadCrumbs";
import ProductHero from "~/components/ProductHero";
import { getProductByKey } from "~/external/commercetools";
import resolveProduct from "~/lib/ctp/resolveProduct";
import Error from "~/views/Error";

function SimpleProductPage(props) {
  const { product, breadcrumbs, productKey, variantKey } = props;
  return (
    <>
      <Head>
        <title>{product.name} | AIAIAI</title>
      </Head>
      <TopSpacerBlock backgroundColor="#F0EFF0" />
      <Block spacingTop={0} spacingBottom={0} backgroundColor="#F0EFF0">
        <BlockContent>
          {breadcrumbs && (
            <BreadCrumbs breadcrumbs={breadcrumbs} autoGenerated={false} />
          )}
        </BlockContent>
      </Block>
      <Block spacingBottom={0} backgroundColor="#F0EFF0">
        <BlockContent>
          <ProductHero product={product} variantKey={variantKey} allowNotifyMe />
        </BlockContent>
      </Block>
    </>
  );
}

SimpleProductPage.getInitialProps = async (ctx) => {
  // try {
  // console.log('Simple product page');

  const { req, ctpCtx, countryData, query } = ctx;
  let { productKey, variantKey } = query;

  // some part specific uid magic
  productKey = productKey.replace(
    /(\-speaker\-units|\-headband|\-earpads|\-cable)/,
    ""
  );

  // console.log(productKey, variantKey);

  let product = await getProductByKey(ctpCtx, productKey, countryData);

  if (
    product.masterData.current.masterVariant.attributesRaw.some(
      (attr) => attr.name === "digital-asset"
    )
  ) {
    throw new Error("Digital assets are currently not supported");
  }

  product = await resolveProduct(ctpCtx, product, countryData);

  // console.log(product);

  const breadcrumbs = []; // TODO

  // attempt to resolve breadcrumbs!
  switch (product.typeKey) {
    case "accessory":
      breadcrumbs.push(["Accessories", "/accessories", "/accessories"]);
      break;
    case "speaker-units":
    case "headband":
    case "earpads":
    case "cable":
      breadcrumbs.push(["Headphones", "/headphones", "/headphones"]);
      breadcrumbs.push([
        "TMA-2 Parts",
        "/headphones/tma-2#parts",
        "/headphones/tma-2#parts",
      ]);
      break;
  }

  // console.log('variantKey', variantKey);

  breadcrumbs.push([product.name]);

  return { product, breadcrumbs, productKey, variantKey };
  // } catch (err) {
  //   console.error(err);
  //   return {};
  // }
};

export default SimpleProductPage;

import Head from "next/head";
import styled from "styled-components";
import Block, { BlockContent, BlockTopSpacer } from "~/components/Block";
import theme from "~/styles/theme";

const NotFoundWrapper = styled.div`
  height: 60vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

function Page(props) {
  const { statusCode, message } = props;

  // getSessionURL
  // const sessionUrl = LogRocket.getSessionURL();
  // console.log(sessionUrl);
  // LogRocket.getSessionURL(sessionURL => {
  //   console.log(sessionURL);
  //   // https://app.logrocket.com/hv6xza/aiaiai/s/2-3f0a16a8-9eaa-4d58-a347-ad2afee52969/0/526ac86e-4e31-4088-92cf-e372bf992224?t=1574422973335
  // });

  return (
    <>
      <Head>
        <title>{message || "An unexpected error occured"} | AIAIAI</title>
      </Head>
      <Block backgroundColor={theme.colors.light} color={theme.colors.dark}>
        <BlockTopSpacer />
        <BlockContent>
          <NotFoundWrapper>
            {statusCode && message ? (
              <div>
                <strong>{statusCode}</strong> · {message}
              </div>
            ) : (
              <div>An unexpected error occured</div>
            )}
          </NotFoundWrapper>
        </BlockContent>
      </Block>
    </>
  );
}

export default Page;

function Bullet(props) {
  const { opacity = 1, size = 5, color = "black" } = props;
  return (
    <svg
      viewBox={`0 0 ${size} ${size}`}
      style={{ opacity, width: `${size}px`, height: `${size}px`, fill: color }}
    >
      <circle cx={size / 2} cy={size / 2} r={size / 2} />
    </svg>
  );
}

export default Bullet;

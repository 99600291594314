import { Fragment } from "react";
import styled from "styled-components";
import Bullet from "~/components/Bullet";
import Link from "~/components/Link";
import { vSpacing } from "~/styles/mixins";
import { rem, smallerThan } from "~/styles/theme";

const BreadCrumbsWrapper = styled.div`
  ${vSpacing};
  position: relative;
  color: ${(p) => p.color || p.theme.colors.dark};
  line-height: 0.75; /* truncate whitespace top and bottom to make dimensions fit with spacing. */
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: ${(p) => rem(`${p.size || 12}px`)};
  > * {
    margin-right: ${rem("5px")};
  }
  /* ${(p) => smallerThan(p.theme.breakpoints.small)} {
    margin-top: ${(p) => p.theme.sizes.vBlockSpacingSmall}rem;
  } */
  a {
    text-decoration: none;
    color: ${(p) => p.color || p.theme.colors.dark};
    &:hover {
      text-decoration: underline;
    }
  }
`;

/**
 * NOTE: please use the `text` props if one [text] · [text]
 * Use spans if custom.
 */

function BreadCrumbs(props) {
  const {
    children,
    color,
    size,
    breadcrumbs = [],
    autoGenerated,
    linkAll = false,
  } = props;

  // console.log(text);

  const isLastBreadcrumb = (i) => i === breadcrumbs.length - 1;

  return (
    <BreadCrumbsWrapper color={color} size={size}>
      <Bullet size={5} style={{ marginRight: rem("7px") }} color={color} />{" "}
      {breadcrumbs.length > 0 &&
        breadcrumbs.map((breadcrumb, i) => {
          const [text, href, as] = breadcrumb;
          const hrefAs = as || href;
          return (
            <Fragment key={i}>
              {linkAll || !isLastBreadcrumb(i) ? (
                <span>
                  <Link href={href} as={hrefAs}>
                    <a>{text}</a>
                  </Link>{" "}
                  {!isLastBreadcrumb(i) && <>· </>}
                </span>
              ) : (
                <span style={{ fontWeight: "bold" }}>{text}</span>
              )}
            </Fragment>
          );
        })}
    </BreadCrumbsWrapper>
  );
}

export default BreadCrumbs;
